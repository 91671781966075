import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { UserContext, UserContextType } from "../../context/userContext";
import { saveStoreSelected } from "../../components/localStorage";
type IResponse = {
  data: Array<any>;
  meta: {
    totalRowCount: number;
  };
};
interface IProducts {
  email: string;
  qrCode: string;
  name: string;
  userType: string;
}
export interface ITableProps {
  tableColumns: any[];
  params?: string;
  currentFetch: (
    token: string,
    onAuthorizationError: () => void,
    params?: string
  ) => any;
}

function Table({ tableColumns, currentFetch, params }: ITableProps) {
  const tableref = useRef<any>(null); // ref => { current: null }

  useEffect(() => {
    if (refetch) {
      setTimeout(() => {
        refetch();
      }, 100);
    }
  }, [params]);

  const {
    userInformation: { token },
    onAuthorizationError,
  } = React.useContext(UserContext) as UserContextType;
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IResponse>({
    queryKey: [
      "table-data",
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
    ],
    queryFn: async () => {
      const storeSelected = await saveStoreSelected.get();
      const paginationParams = `?start=${
        pagination.pageIndex * pagination.pageSize
      }&size=${pagination.pageSize}&store=${storeSelected?.cod_store}${
        params ? "&" + params : ``
      }`;
      const response = await currentFetch(
        token,
        onAuthorizationError,
        paginationParams.trim()
      );
      const json = response as IResponse;
      return json;
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<IProducts>[]>(
    //column definitions...
    () => tableColumns,
    []
  );
  const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.grey, //swap in the secondary color as the primary for the table
          info: {
            main: "#ffffff", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "rgb(0, 57, 100)", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: "none",
                paddingTop: 0,
                paddingBottom: 0,

                ".MuiBox-root .MuiInputBase-root": {
                  color: "black !important",
                },
              },
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: "transparent !important",
                ".MuiTableCell-root": {
                  color: "white",
                },
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottom: "none",
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: 1,
                  letterSpacing: 0.5,
                  textTransform: "uppercase",
                },
                "& .MuiTableCell-root .MuiTableSortLabel-root .MuiTableSortLabel-icon":
                  {
                    color: "rgb(204, 204, 204) !important",
                  },
                "& .MuiTableCell-paddingCheckbox": {
                  paddingTop: 4,
                  paddingBottom: 4,
                },
                "& th:first-child": {
                  borderTopLeftRadius: 10,
                },
                "& th:last-child": {
                  borderTopRightRadius: 10,
                },
              },
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                backgroundColor: "rgb(0, 57, 100) !important",
                ".MuiTableCell-root": {
                  color: "white",
                },
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                color: "white",
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                ":disabled": {
                  color: "",
                },
                color: "#ccc",
              },
            },
          },
          MuiTableBody: {
            styleOverrides: {
              root: {
                ".MuiTableCell-root.MuiTableCell-body": {
                  color: "#000 !important",
                },
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: meta?.totalRowCount ?? 0,
    manualPagination: true,
    enableFullScreenToggle: false,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },

    muiTableProps: {
      classes: "css-118e0ip-MuiTableHead-root",
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: "normal",

        color: "white",
      },
    },
    onPaginationChange: setPagination,
    localization: MRT_Localization_ES,

    //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />;
    </ThemeProvider>
  );
}

const GlobalTable = ({ tableColumns, currentFetch, params }: ITableProps) => (
  <Table
    tableColumns={tableColumns}
    currentFetch={currentFetch}
    params={params}
  />
);
export default GlobalTable;
