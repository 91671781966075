import { SeparatorRow } from "../../components/utils";
import React, { useState } from "react";
import { Col, Container, Input, Row } from "reactstrap";
import { fetchLoginUser } from "../../services/fetch-admn-api";
import { UserContext, UserContextType } from "../../context/userContext";

export function LoginView() {
  const [messageError, setMessageError] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const { saveLogin, onAuthorizationError, showOrHideLoading, isLoading } =
    React.useContext(UserContext) as UserContextType;

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPin(value);
  };

  const handleSendLogin = async () => {
    setMessageError("");
    showOrHideLoading(true);
    if (email.length === 0) {
      setMessageError("Por favor completar campo de correo");
      showOrHideLoading(false);
      return;
    }

    if (pin.length === 0) {
      setMessageError("Por favor completar campo de pin");
      showOrHideLoading(false);
      return;
    }
    const response = await fetchLoginUser({ email, pin }, onAuthorizationError);
    if (response.user) {
      const { user } = response;
      saveLogin(user);
      setTimeout(() => {
        window.location.href = "/admin/products";
        showOrHideLoading(false);
      }, 200);
    } else if (response.message) {
      setMessageError(response.message);
      showOrHideLoading(false);
    }
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendLogin();
    }
  };

  return (
    <Row className="container-page align-content-center justify-content-center mx-0">
      <Col>
        <Container className="">
          <Row className="justify-content-center align-items-center">
            <Col xs="12" sm={9} md={9} lg={6}>
              <Container className="card-login">
                <Row className="my-3 justify-content-center">
                  <Col xs="auto">
                    <span className="header-title">Iniciar sesion</span>
                  </Col>
                </Row>
                <SeparatorRow height={20} />
                <Row className="my-3">
                  <Col>
                    <div className="container-input-login">
                      <Input
                        type="email"
                        className="form-control"
                        placeholder="e-mail"
                        onChange={handleChangeEmail}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <div className="container-input-login">
                      <Input
                        type="password"
                        className="form-control"
                        placeholder="----"
                        onChange={handleChangePin}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <SeparatorRow height={20} />
                <Row className="my-3 justify-content-center">
                  <Col xs="auto">
                    <button
                      disabled={isLoading}
                      className="btn-login"
                      onClick={handleSendLogin}
                    >
                      Login
                    </button>
                  </Col>
                </Row>
                <SeparatorRow height={20} />
                {messageError && (
                  <Row className="my-3 justify-content-center">
                    <Col xs="auto">
                      <span className="schedule-message-error">
                        {messageError}
                      </span>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
}
