import React, { useEffect, useState } from "react";
import { Col, Container, Input, Row } from "reactstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import samplePDF from "../../assets/img/example.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

interface IPDFViwer {
  url: string;
  onSetPage?: (page: number) => void;
}
export function PDFViwer({ url }: IPDFViwer) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_MAX_HEIGHT = 600;

  useEffect(() => {
    console.log("log");
    return () =>
      document
        .querySelector("#pages-doc")!
        .removeEventListener("scroll", (e: any) => scrollListener(e, numPages));
  }, []);

  const CONTAINER_PADDING = 12;
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    document
      .querySelector("#pages-doc")!
      .addEventListener("scroll", (e: any) => scrollListener(e, numPages));
    document.querySelector("#current-page")!.innerHTML = 1 + "";
  };

  const scrollListener = (el: any, numPages: number) => {
    const scrollTop = el.target.scrollTop;
    const scrollHeight = el.target.scrollHeight;
    const value = scrollHeight / numPages;
    let result = scrollTop / value;
    const newResult = Math.trunc(result);
    document.querySelector("#current-page")!.innerHTML = newResult + 1 + "";
  };

  const changePage = (offset: number) => {
    setPageNumber(offset);
  };

  const goToPage = (page: number) => {
    changePage(page);
  };

  return (
    <Row className="container-page align-content-center justify-content-center mx-0">
      <Col xs="auto">
        <div
          id="pdf-container"
          style={{
            border: "none",
            display: "flex",
            width: "100%",
            borderRadius: "3px",
            padding: `${CONTAINER_PADDING}px`,
          }}
        >
          <Document file={samplePDF} onLoadSuccess={onDocumentLoadSuccess}>
            <Row className="justify-content-center ">
              <Col xs="auto">
                <button onClick={() => goToPage(5)}>Go5</button>
              </Col>
              <Col xs="auto">
                <button onClick={() => goToPage(1)}>Go1</button>
              </Col>
              <Col xs="auto" className="">
                <b>
                  <span id={"current-page"}></span>
                  {`/${numPages}`}
                </b>
              </Col>
            </Row>
            <div
              id="pages-doc"
              style={{
                maxHeight: `${PAGE_MAX_HEIGHT}px`,
                overflowY: "scroll",
                overflowX: "hidden",
                border: "2px solid lightgray",
                borderRadius: "5px",
              }}
            >
              {numPages >= 0 && (
                <>
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index + 1 + new Date().getTime()}`}>
                      <Page
                        key={`page_${index + 1 + new Date().getTime()}`}
                        pageNumber={index + 1}
                        inputRef={(ref) => {
                          if (ref && pageNumber === index + 1) {
                            ref.scrollIntoView();
                          }
                        }}
                        loading={<></>}
                      />
                      {index < numPages && (
                        <div
                          style={{
                            border: "2px solid lightgray",
                            height: 2,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </Document>
        </div>
      </Col>
    </Row>
  );
}
