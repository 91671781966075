import { IStore, IUserInfo } from "../context/userContext";
import localforage from "localforage";

const USER_INFORMATION = "user_information";
const STORE_SELECTED = "store_selected";

export const saveUserInformation = {
  set(userInformation: IUserInfo) {
    localforage.setItem(USER_INFORMATION, userInformation);
  },
  async get() {
    const value: IUserInfo | null = await localforage.getItem<IUserInfo>(
      USER_INFORMATION
    );
    return value;
  },
};

export const saveStoreSelected = {
  set(storeSelected: IStore) {
    localforage.setItem(STORE_SELECTED, storeSelected);
  },
  async get() {
    const value: IStore | null = await localforage.getItem<IStore>(
      STORE_SELECTED
    );
    return value;
  },
};
