import { Col, Modal, ModalBody, Row } from "reactstrap";
import ReactLoading, { LoadingType } from "react-loading";

export const dateToString = (date: Date): string =>
  `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}`;
export const pad = (num: any): string => `${num}`.padStart(2, "0");
interface IProps {
  height: number;
}
export const SeparatorRow = ({ height }: IProps) => {
  return <Row style={{ height: height }}></Row>;
};
export enum User_Type {
  SuperAdmin = "1",
  Admin = "2",
  user = "3",
}

interface ILoadingProps {
  isLoading: boolean;
  type?: LoadingType;
  color?: string;
}

export function LoadingOverlay({ isLoading, type, color }: ILoadingProps) {
  const selectedType = type ?? "spin";
  const selectedColor = color ?? "#ffffff";
  return (
    <Modal
      isOpen={isLoading}
      toggle={() => {}}
      centered
      fade={false}
      contentClassName="bg-transparent border-0"
    >
      <ModalBody>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ReactLoading type={selectedType} color={selectedColor} />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
