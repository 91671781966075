import { SeparatorRow } from "../components/utils";
import React, { useEffect, useState } from "react";
// reactstrap components
import { CardHeader, Row, Col, Input } from "reactstrap";
import { fetchCreateUser } from "../services/fetch-admn-api";
import { UserContextType } from "../context/userContext";
import { UserContext } from "../context/userContext";
const USER_TYPE = [
  { id: 1, name: "Super Admin" },
  { id: 2, name: "Admin" },
  { id: 3, name: "User" },
];
export function NewUser({
  onToggle,
  onClose,
}: {
  onToggle: () => void;
  onClose: () => void;
}) {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [qrCode, setQrCode] = useState<string>("");
  const [messageError, setMessageError] = useState("");

  const [userType, setUserType] = useState<string>(USER_TYPE[0].id.toString());
  const {
    showOrHideLoading,
    userInformation: { token },
    onAuthorizationError,
  } = React.useContext(UserContext) as UserContextType;

  useEffect(() => {
    //fetchGetUsers(token);
  }, []);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleChangeUserType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserType(value);
  };

  const handleCreate = async () => {
    setMessageError("");
    if (email.length === 0) {
      setMessageError("Por favor completar el campo de correo.");

      return;
    }

    if (name.length === 0) {
      return;
    }
    const body = {
      name,
      userType,
      email,
    };
    showOrHideLoading(true);
    const response = await fetchCreateUser(body, token, onAuthorizationError);
    showOrHideLoading(false);

    if (response.exist) {
      setMessageError("El correo ya esta registrado.");

      return;
    }

    setQrCode(response.qrCode || "");
    setName("");
    setEmail("");
    setUserType(USER_TYPE[0].id.toString());

    setQrCode("");
    onToggle();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div>
              <CardHeader>
                <span className="title-header">Crear usuario</span>
              </CardHeader>
              <SeparatorRow height={20} />
              <div className="">
                <Row className="justify-content-center">
                  <Col xs="10">
                    <Row className="align-items-center">
                      <Col xs="3">
                        <span>E-mail</span>
                      </Col>
                      <Col className="container-create-user">
                        <Input
                          placeholder="local@gmail.com"
                          type="email"
                          onChange={handleChangeEmail}
                          value={email}
                        />
                      </Col>
                    </Row>
                    <SeparatorRow height={20} />
                    <Row className="align-items-center">
                      <Col xs="3">
                        <span>Nombre</span>
                      </Col>
                      <Col className="container-create-user">
                        <Input
                          placeholder="nombre"
                          type="text"
                          onChange={handleChangeName}
                          value={name}
                        />
                      </Col>
                    </Row>
                    <SeparatorRow height={20} />
                    <Row className="align-items-center">
                      <Col xs="3">
                        <span>Tipo de usuario</span>
                      </Col>
                      <Col className="container-create-user">
                        <Input
                          placeholder="Type"
                          type="select"
                          onChange={handleChangeUserType}
                          value={userType}
                        >
                          {USER_TYPE.map((user) => {
                            return (
                              <option key={user.id} value={user.id}>
                                {user.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                    </Row>
                    {messageError && (
                      <>
                        <SeparatorRow height={20} />
                        <Row className="my-3 justify-content-center">
                          <Col xs="auto">
                            <span className="schedule-message-error">
                              {messageError}
                            </span>
                          </Col>
                        </Row>
                      </>
                    )}
                    <SeparatorRow height={50} />
                    <Row className="align-items-center justify-content-center">
                      <Col xs="auto">
                        <button className="btn-save" onClick={handleCreate}>
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="text-center">
                    {qrCode && <img src={qrCode} alt={"QR"} />}
                  </Col> */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
