import { SeparatorRow, User_Type } from "../../components/utils";
import React, { useMemo, useEffect, useState } from "react";
import { type MRT_ColumnDef } from "material-react-table";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";

import {
  fetchEnterprises,
  fetchGetAllCajas,
  updateCashier,
} from "../../services/fetch-admn-api";
import GlobalTable from "../../components/GlobalTable/GlobalTable";
import { UserContextType, UserContext } from "../../context/userContext";

export interface ICashier {
  _id: string;
  ruc: string;
  sucursal: string;
  puntoDeFacturacion: string;
  status: boolean;
  created: string;
  validTo: string;
}

interface IEnterprise {
  ruc: string;
  name: string;
  _id: string;
}

export function Invoice() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [cashier, setCashier] = useState<ICashier | undefined>(undefined);
  const [enterprises, setEnterprises] = useState<IEnterprise[]>([]);
  const [enterpriseSelected, setEnterpriseSelected] = useState<
    IEnterprise | undefined
  >(undefined);

  const { showOrHideLoading, userInformation, onAuthorizationError } =
    React.useContext(UserContext) as UserContextType;
  useEffect(() => {
    getEnterprises();
  }, []);

  const getEnterprises = async () => {
    const response = await fetchEnterprises(
      userInformation.token,
      onAuthorizationError
    );
    if (response.enterprises && response.enterprises.lenght === 1) {
      setEnterpriseSelected(response.enterprises[0]);
    }
    setEnterprises(response.enterprises || []);
  };

  const handleUpdateCashier = async () => {
    showOrHideLoading(true);
    setUpdate(false);
    await updateCashier(userInformation.token, cashier, onAuthorizationError);
    setIsOpen(false);
    setUpdate(true);
    showOrHideLoading(false);
  };

  const columns = useMemo<MRT_ColumnDef<ICashier[]>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "ruc",
        header: "Ruc",
        enableHiding: true,
      },
      {
        accessorKey: "sucursal",
        header: "#Sucursal",
      },
      {
        accessorKey: "nombreSucursal",
        header: "Sucursal",
      },
      {
        accessorKey: "puntoDeFacturacion",
        header: "Punto de facturacion",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ renderedCellValue, row }) => {
          const onClick = (cashier?: any) => {
            setCashier(cashier._valuesCache);
            setIsOpen(!isOpen);
          };

          return (
            <Button
              className={
                renderedCellValue?.toString() === "false"
                  ? "btn-gray"
                  : "btn-green"
              }
              onClick={() => onClick(row)}
            >
              {renderedCellValue?.toString() === "false"
                ? "Desactivado"
                : "Activado"}
            </Button>
          );
        },
      },
      {
        accessorKey: "created",
        header: "Created",
      },
    ],
    []
  );

  const handleChangeEnterprise = (e: any) => {
    const { value } = e.target;
    if (value) {
      const element = enterprises.find(
        (enterprise) => enterprise.ruc === value
      );
      setEnterpriseSelected(element);
    } else {
      setEnterpriseSelected(undefined);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody>
          <SeparatorRow height={50} />
          <Row className="justify-content-center">
            <Col xs="auto">
              Esta seguro que desea{" "}
              {cashier && cashier.status ? "descativar " : "activar "}
              el cashier?
            </Col>
          </Row>
          <SeparatorRow height={50} />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button onClick={() => setIsOpen(false)}>Cancelar</Button>
            </Col>
            <Col xs="auto">
              <Button className="btn-green" onClick={handleUpdateCashier}>
                {cashier && cashier.status ? "Descativar" : "Activar"}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Register</CardTitle>
                  </Col>
                  <Col xs="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <span>Empresa:</span>
                  </Col>
                  <Col xs="3">
                    <select
                      onChange={handleChangeEnterprise}
                      className="form-control dropdown-app"
                    >
                      {enterprises.map(({ name, _id, ruc }) => (
                        <option value={ruc}>{name}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
                {enterpriseSelected && (
                  <GlobalTable
                    tableColumns={columns}
                    currentFetch={fetchGetAllCajas}
                    params={
                      enterpriseSelected ? `ruc=${enterpriseSelected.ruc}` : ""
                    }
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
