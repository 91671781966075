export const BASE_URL = "https://service2.appmsl.com";
//export const BASE_URL = "http://localhost:5022";
export interface ICreateUserBody {
  name: string;
  email: string;
  userType: string;
}
export const fetchCreateUser = async (
  body: ICreateUserBody,
  token: string,
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/user/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchGetUsers = async (
  token: string,
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/user/all`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response.users || [];
};

export const fetchGenerateNewSecretUser = async (
  body: {
    token: string;
    email: string;
  },
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/user/generate/secret`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${body.token}`,
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchRemoveSecretUser = async (
  body: {
    token: string;
    email: string;
  },
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/user/remove/secret`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${body.token}`,
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchLoginUser = async (
  body: { pin: string; email: string },
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchGetAllProducts = async (
  token: string,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/product/all${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchGetPendingProducts = async (
  token: string,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/product/pending${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchGetStores = async (
  token: string,
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/store/all`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response.stores || [];
};

export const fetchGetAllLogs = async (
  token: string,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/console/logs${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchGetAllTasks = async (
  token: string,
  onAuthorizationError: () => void,
  store: string
) => {
  const response = await fetch(`${BASE_URL}/api/console/tasks?store=${store}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response.taskList || [];
};

export const fetchStartTask = async (
  body: {
    token: string;
    name: string;
    store: string;
  },
  onAuthorizationError: () => void
) => {
  const response = await fetch(`${BASE_URL}/api/console/task/start`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${body.token}`,
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};
export const fetchGetAllCajas = async (
  token: string,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/console/cajas${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const updateCashier = async (
  token: string,
  body: any,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/console/cajas/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};

export const fetchEnterprises = async (
  token: string,
  onAuthorizationError: () => void,
  params?: string
) => {
  const response = await fetch(`${BASE_URL}/api/console/enterprises`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data: any) => {
      if (data.status === 200) {
        return data.json();
      } else if (data.status === 401) {
        onAuthorizationError();
      }
      return data;
    })
    .catch(() => ({ ok: false }));
  return response;
};
