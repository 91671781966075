import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import PerfectScrollbar from "perfect-scrollbar";

import logo from "logo.svg";
import {
  IStore,
  UserContext,
  UserContextType,
} from "../../context/userContext";
import { saveStoreSelected } from "../../components/localStorage";

var ps: any;

function Sidebar(props: any) {
  const location = useLocation();
  const sidebar = React.useRef<any>();
  const { stores, storeSelected, setStoreSelected } = React.useContext(
    UserContext
  ) as UserContextType;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: any) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const handleSaveStores = async (store: IStore) => {
    setStoreSelected(store);
    await saveStoreSelected.set(store);
    window.location.reload();
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && sidebar.current) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div
      className="sidebar schedule"
      // data-color={props.bgColor}
      // data-active-color={props.activeColor}
    >
      <div className="logo">
        
        <Row className="justify-content-end w-100 align-items-center">
          <Col className="px-1 text-lg-start text-center">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                className="dropdown-store-menu my-0 text-left px-3"
              >
                {storeSelected ? storeSelected.name : "Seleciona una tienda"}
              </DropdownToggle>
              <DropdownMenu className="store-drop-down" dark>
                {stores.map((store, index) => (
                  <DropdownItem
                    onClick={() => handleSaveStores(store)}
                    key={index}
                    className={classnames({
                      selected:
                        storeSelected &&
                        storeSelected.idstores === store.idstores,
                    })}
                  >
                    {store.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>

      <div className="sidebar-wrapper schedule" ref={sidebar}>
        <Nav>
          {props.routes.map((prop: any, key: any) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink to={prop.layout + prop.path} className="nav-NavLink">
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
      <Row className="justify-content-center">
        <Col xs="auto">
          <a
            href="https://www.creative-tim.com"
            className="simple-text logo-normal"
          >
            AdminApp
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default Sidebar;
