import { SeparatorRow, User_Type } from "../components/utils";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import { UserContext } from "../context/userContext";
import { UserContextType } from "../context/userContext";
import { NewUser } from "./NewUser";
import {
  fetchGenerateNewSecretUser,
  fetchGetUsers,
  fetchRemoveSecretUser,
} from "../services/fetch-admn-api";
interface IProps {
  isOpen: boolean;
  onAction: () => void;
  onClose: () => void;
  userName: string;
  qrCode?: string;
}
function WarnignModal({ onAction, isOpen, onClose, userName }: IProps) {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <SeparatorRow height={40} />
        <Row className="justify-content-center">
          <Col xs="auto">
            <i className="nc-icon nc-simple-remove mx-2 round" />
          </Col>
        </Row>
        <SeparatorRow height={50} />
        <Row className="justify-content-center">
          <Col xs="8" className="text-center">
            <span className="message-warning">
              Esta seguro que desea remover el acceso al usuario{" "}
              <b>{userName}</b>
            </span>
          </Col>
        </Row>
        <SeparatorRow height={50} />
        <Row className="justify-content-center">
          <Col xs="auto">
            <button
              type="button"
              className="btn-cancel remove"
              onClick={onClose}
            >
              Cancelar
            </button>
          </Col>
          <Col xs="auto">
            <button
              type="button"
              onClick={onAction}
              className="btn-confirm-remove"
            >
              Remover
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

function QRCodeModal({ onAction, qrCode, isOpen, onClose, userName }: IProps) {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <Row className="justify-content-end">
          <Col xs="auto">
            <button className="bg-transparent border-0">
              <i
                onClick={onClose}
                className="nc-icon nc-simple-remove bold mx-2 "
              />
            </button>
          </Col>
        </Row>
        <SeparatorRow height={50} />
        <Row className="justify-content-center">
          <Col xs="8" className="text-center">
            <img src={qrCode} alt="qrcode" />
          </Col>
        </Row>
        <SeparatorRow height={50} />
        <Row className="justify-content-center">
          <Col xs="auto">
            <span className="message-warning">
              <b>{userName}</b>
            </span>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
interface ICreateUserProps {
  isOpen: boolean;
  onAction: () => void;
  onClose: () => void;
}
function CreateUserModal({ onAction, isOpen, onClose }: ICreateUserProps) {
  const handleRefresh = () => {
    onAction();
    onClose();
  };
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <Row className="justify-content-end">
          <Col xs="auto">
            <button className="bg-transparent border-0">
              <i
                onClick={onClose}
                className="nc-icon cursor-pointer nc-simple-remove bold mx-2 "
              />
            </button>
          </Col>
        </Row>
        <NewUser onToggle={handleRefresh} onClose={onClose} />
      </ModalBody>
    </Modal>
  );
}

interface IUser {
  email: string;
  qrCode: string;
  name: string;
  userType: string;
}
export function UserList() {
  const { showOrHideLoading, userInformation, onAuthorizationError } =
    React.useContext(UserContext) as UserContextType;
  const [users, setUsers] = useState<IUser[]>([]);
  const [isOpenWarningModal, setWarningModal] = useState<boolean>(false);
  const [isOpenQRCodeModal, setQRCodeModal] = useState<boolean>(false);
  const [isOpenCreateUserModal, setCreateUserModal] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<IUser | undefined>(
    undefined
  );

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    console.log("I have been mounted");
    showOrHideLoading(true);
    const users = await fetchGetUsers(
      userInformation.token,
      onAuthorizationError
    );
    setUsers(users);
    setTimeout(() => {
      showOrHideLoading(false);
    }, 500);
  };

  const removeQrCode = (user: IUser) => {
    setUserSelected(user);
    setWarningModal(!isOpenWarningModal);
  };

  const showQrCode = (user: IUser) => {
    setUserSelected(user);
    setQRCodeModal(!isOpenQRCodeModal);
  };

  const handleGenerate = async (user: IUser) => {
    showOrHideLoading(true);
    const response = await fetchGenerateNewSecretUser(
      {
        email: user.email,
        token: userInformation.token,
      },
      onAuthorizationError
    );

    console.log("response", response);
    showOrHideLoading(false);
    if (!response.updated) {
      return;
    }
    getUsers();
  };

  const handleActionRemoveSecret = async () => {
    if (userSelected) {
      showOrHideLoading(true);
      const response = await fetchRemoveSecretUser(
        {
          email: userSelected.email,
          token: userInformation.token,
        },
        onAuthorizationError
      );

      console.log("response", response);
      showOrHideLoading(false);
      if (!response.updated) {
        return;
      }
      getUsers();
      setWarningModal(false);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Usuarios</CardTitle>
                  </Col>
                  <Col xs="auto">
                    <button
                      type="button"
                      className="btn-create "
                      onClick={() => setCreateUserModal(true)}
                    >
                      Nuevo
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <SeparatorRow height={10} />
              <CardBody>
                <Table responsive>
                  <thead className="text-primary header-table-row ">
                    <tr className="">
                      <th className="text-center">Correo</th>
                      <th className="text-center">Nombre</th>
                      <th className="text-center d-none">Tipo Usuario</th>
                      <th className="text-center">QrCode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(
                      ({ name, email, qrCode, userType }, index: number) => (
                        <tr key={index}>
                          <td className="text-center">{email}</td>
                          <td className="text-center">{name}</td>
                          <td className="text-center d-none">{userType}</td>
                          <td className="text-center">
                            <Row className="algin-items-center justify-content-center">
                              {qrCode && (
                                <Col xs="auto">
                                  <button
                                    type="button"
                                    className="btn-remove align-items-center d-flex mx-1"
                                    onClick={() =>
                                      removeQrCode({
                                        name,
                                        email,
                                        userType,
                                        qrCode,
                                      })
                                    }
                                  >
                                    <i className="nc-icon nc-simple-remove mr-1" />
                                    Remover
                                  </button>
                                </Col>
                              )}
                              {qrCode && (
                                <Col xs="auto">
                                  <button
                                    type="button"
                                    className="btn-view align-items-center d-flex mx-1"
                                    onClick={() =>
                                      showQrCode({
                                        name,
                                        email,
                                        userType,
                                        qrCode,
                                      })
                                    }
                                  >
                                    <i className="nc-icon nc-alert-circle-i mr-1" />
                                    Ver
                                  </button>
                                </Col>
                              )}
                              {!qrCode &&
                                userInformation.userType.toString() ===
                                  User_Type.SuperAdmin && (
                                  <Col xs="auto">
                                    <button
                                      type="button"
                                      className="btn-view align-items-center d-flex mx-1"
                                      onClick={() =>
                                        handleGenerate({
                                          name,
                                          email,
                                          userType,
                                          qrCode,
                                        })
                                      }
                                    >
                                      <i className="nc-icon nc-settings-gear-65 mr-1" />
                                      Generar
                                    </button>
                                  </Col>
                                )}
                            </Row>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <WarnignModal
        isOpen={isOpenWarningModal}
        onAction={handleActionRemoveSecret}
        onClose={() => setWarningModal(false)}
        userName={userSelected ? userSelected.name : ""}
      />
      <QRCodeModal
        isOpen={isOpenQRCodeModal}
        onAction={() => {}}
        onClose={() => setQRCodeModal(false)}
        userName={userSelected ? userSelected.name : ""}
        qrCode={userSelected ? userSelected.qrCode : ""}
      />
      <CreateUserModal
        isOpen={isOpenCreateUserModal}
        onAction={getUsers}
        onClose={() => setCreateUserModal(false)}
      />
    </>
  );
}
