import UserProvider, {
  UserContext,
  UserContextType,
} from "../context/userContext";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginView } from "../views/Login";
import DashboardAdmin from "./DashboardAdmin";
import { PDFViwer } from "../views/Example";

export const AdminApp = () => {
  const { isLogin } = React.useContext(UserContext) as UserContextType;

  useEffect(() => {
    if (!isLogin && window.location.pathname !== "/login") {
      //window.location.pathname = "/login";
    } else if (isLogin && window.location.pathname === "/login") {
      //window.location.pathname = "/admin/dashboard";
    }
  }, [isLogin]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginView />} />
        <Route path="/example" element={<PDFViwer url={""} />} />

        {isLogin ? (
          <>
            <Route path="/admin/*" element={<DashboardAdmin />} />
            <Route path="/" element={<Navigate to="/admin" replace />} />
          </>
        ) : (
          <Route path="/" element={<Navigate to="/login" replace />} />
        )}
        {/* <Route
          path="*"
          element={<div style={{ backgroundColor: "red" }}>Not found</div>}
        /> */}
      </Routes>
    </BrowserRouter>
  );
};
