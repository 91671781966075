import Notifications from "./views/Notifications";
import { UserList } from "./views/UserList";
import { User } from "./views/User";
import { PendingProduct } from "./views/PendingProductos";
import { Tasks } from "./views/Tasks";
import { Logs } from "./views/Logs";
import { Invoice } from "./views/Invoice";
var routes = [
  {
    path: "/cashier",
    name: "Cashier",
    icon: "nc-icon nc-diamond",
    component: <Invoice />,
    layout: "/admin",
  },
  // {
  //   path: "/pending-products",
  //   name: "Productos pendientes",
  //   icon: "nc-icon nc-bank",
  //   component: <PendingProduct />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/logs",
  //   name: "Logs",
  //   icon: "nc-icon nc-bell-55",
  //   component: <Logs />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tasks",
  //   name: "Tareas",
  //   icon: "nc-icon nc-tile-56",
  //   component: <Tasks />,
  //   layout: "/admin",
  // },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-single-02",
    component: <UserList />,
    layout: "/admin",
  },
];
export default routes;
