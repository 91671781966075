import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import routes from "../routes";
import DemoNavbar from "../components/Navbars/ScheduleNavBar";
import Footer from "../components/Footer/Footer";
import { UserContext, UserContextType } from "../context/userContext";

var ps: any;

function DashboardAdmin(props: any) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef<any>();
  const location = useLocation();
  const { userInformation } = React.useContext(UserContext) as UserContextType;
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
  }, [location]);
  const handleActiveClick = (color: any) => {
    setActiveColor(color);
  };
  const handleBgClick = (color: any) => {
    setBackgroundColor(color);
  };
  let newRoutes = routes;

  if (parseInt(userInformation.userType) !== 1) {
    newRoutes = routes.filter((item) => item.path !== "/users");
  }
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={newRoutes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Routes>
          {routes.map((prop, key) => {
            return (
              <Route path={prop.path} element={prop.component} key={key} />
            );
          })}
        </Routes>
        <Footer fluid />
      </div>
      {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
      /> */}
    </div>
  );
}
export default DashboardAdmin;
